import type { Series } from '@mentimeter/http-clients';
import {
  Channels,
  SeriesPrivateEvents,
  useSubscribe,
} from '@mentimeter/realtime';
import React from 'react';
import { useSWRConfig } from 'swr';
import { MentiError, captureException } from '@mentimeter/errors';
import { resultCacheKey, seriesHasResultsCacheKey } from './cache-keys';

interface Config {
  device_id: string;
  seriesId: Series['id'];
  shouldSubscribe?: boolean;
}

type InternalConfig = Pick<Config, 'device_id'>;

export const SubscribeNetworkCache = ({
  seriesId,
}: Pick<Config, 'seriesId'>) => {
  const { mutate } = useSWRConfig();
  useSubscribe(
    { channel: Channels.SERIES_PRIVATE, value: seriesId },
    SeriesPrivateEvents.UPDATE_NETWORK_CACHE,
    (event) => {
      if (event.data.cacheKey) mutate(event.data.cacheKey);
      if (event.data.cacheKeys)
        event.data.cacheKeys.forEach((cacheKey) => mutate(cacheKey));
    },
  );

  return null;
};

const context = React.createContext<InternalConfig | undefined>(undefined);

const { Provider } = context;

export const CoreHooksProvider = ({
  children,
  shouldSubscribe = true,
  seriesId,
  device_id,
}: Config & {
  children: React.ReactNode;
}) => {
  return (
    <>
      {shouldSubscribe && <SubscribeNetworkCache seriesId={seriesId} />}
      <Provider value={{ device_id }}>{children}</Provider>
    </>
  );
};

export const useSubscribeToResults = (
  seriesId: string,
  {
    shouldSubscribe = true,
    shouldRevalidate = true,
  }: {
    shouldSubscribe?: boolean;
    shouldRevalidate?: boolean;
  } = {},
) => {
  const { mutate } = useSWRConfig();
  useSubscribe(
    { channel: Channels.SERIES_PRIVATE, value: seriesId, shouldSubscribe },
    SeriesPrivateEvents.RESULT,
    (event) => {
      const { question_id, result } = event.data.payload;
      try {
        mutate(resultCacheKey(question_id), result, {
          revalidate: shouldRevalidate,
        });
        mutate(seriesHasResultsCacheKey(seriesId));
      } catch (error) {
        // Fall back to just mutating the cache key without the result payload
        mutate(resultCacheKey(question_id));
        if (error && typeof error === 'object') {
          Object.assign(error, { websocketMessage: event });
          captureException(
            new MentiError('Error parsing subscribe to result', {
              cause: error,
              feature: 'live',
            }),
          );
        }
      }
    },
  );
  useSubscribe(
    { channel: Channels.SERIES_PRIVATE, value: seriesId, shouldSubscribe },
    SeriesPrivateEvents.RESULT_HAS_CHANGED,
    (event) => {
      if (event.data.payload.question_id) {
        mutate(resultCacheKey(event.data.payload.question_id));
        mutate(seriesHasResultsCacheKey(seriesId));
      }
    },
  );
};

export const useCoreHookContext = () => {
  const value = React.useContext(context);
  if (!value) {
    throw new Error(
      'could not find context. ensure that call to hook is wrapped in a CoreHooksProvider',
    );
  }

  return value;
};
