'use client';
import React, { forwardRef } from 'react';
import type { LinkProps } from 'next/link';
import NextLink from 'next/link';
import type { LinkT as RagnarLinkT } from '@mentimeter/ragnar-ui';
import { Link as RagnarLink } from '@mentimeter/ragnar-ui';
import { getRouteInternally } from './getRouteInternally';

export interface LinkT
  extends Omit<RagnarLinkT, 'href'>,
    Pick<LinkProps, 'href'> {}

export const Link = forwardRef<HTMLAnchorElement, LinkT>(
  ({ as, children, href, ...rest }, ref) => {
    const { shouldRouteInternally, internalLink } = getRouteInternally(
      href.toString(),
    );

    if (shouldRouteInternally) {
      return (
        <RagnarLink {...rest} as={NextLink} href={internalLink} ref={ref}>
          {children}
        </RagnarLink>
      );
    }

    return (
      <RagnarLink {...rest} href={href.toString()} ref={ref}>
        {children}
      </RagnarLink>
    );
  },
);
