import { MentiError, captureException } from '@mentimeter/errors';
import {
  Channels,
  SeriesPrivateEvents,
  usePublish,
} from '@mentimeter/realtime';
import React from 'react';
import type { KeyedMutator, SWRConfiguration } from 'swr';
import useSWR, { useSWRConfig, unstable_serialize as serialize } from 'swr';

export const useData = <T>(
  {
    cacheKey,
    fetcher,
    seriesId,
    skip,
  }: {
    cacheKey: string | Array<string | number> | undefined;
    seriesId: string | undefined;
    fetcher: () => Promise<T>;
    skip?: boolean;
  },
  config?: SWRConfiguration<T>,
): {
  data: T | undefined;
  lazyData: () => T | undefined;
  mutate: KeyedMutator<T>;
  error: any;
  revalidate: () => Promise<{ responseForBackwardCompat: T | undefined }>;
} => {
  const { data, mutate, error } = useSWR(
    skip ? null : cacheKey,
    fetcher,
    config,
  );
  const { cache, fallback } = useSWRConfig();
  const lazyData = React.useCallback((): T | undefined => {
    const key = cacheKey ? serialize(cacheKey) : null;
    return key ? cache.get(key)?.data ?? fallback[key] : null;
  }, [cache, cacheKey, fallback]);

  const publish = usePublish(
    seriesId ? { channel: Channels.SERIES_PRIVATE, value: seriesId } : null,
  );

  return {
    data,
    lazyData,
    error,
    mutate: React.useCallback(
      async (...args) => {
        try {
          if (cacheKey) {
            publish(SeriesPrivateEvents.UPDATE_NETWORK_CACHE, {
              cacheKey: serialize(cacheKey),
            });
          }
          return await mutate(...args);
        } catch (error) {
          captureException(
            new MentiError('Could not mutate, SWR bug?', {
              feature: 'live',
            }),
          );
          return undefined;
        }
      },
      [cacheKey, mutate, publish],
    ),
    revalidate: React.useCallback(async () => {
      const responseForBackwardCompat = await mutate<T>();
      return { responseForBackwardCompat };
    }, [mutate]),
  };
};
