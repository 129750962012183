import update from 'immutability-helper';
import { useCallback, useMemo } from 'react';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/vanilla/shallow';
import type {
  CoreGlobalState,
  EditorFlags,
  SetSelectedSlideIds,
} from './types';

export const useGlobalState = createWithEqualityFn<CoreGlobalState>(
  (set) => ({
    selectedSlideIds: [],
    setSelectedSlideIds: (slides) =>
      set((state) => {
        if (typeof slides === 'function') {
          return {
            selectedSlideIds: slides(state.selectedSlideIds),
          };
        }
        return { selectedSlideIds: slides };
      }),

    editorFlags: {
      hasPresented: false,
      newPresentationMode: false,
      questionAddMode: false,
      operationLock: false,
      isUpdating: false,
    },
    setEditorFlag: (key, value) =>
      set((state) => ({
        editorFlags: update(state.editorFlags, { [key]: { $set: value } }),
      })),

    persisting: [],
    lastActiveRequest: Date.now(),
    startPersisting: (cacheKey) =>
      set((state) => ({
        persisting: Array.from(
          new Set(update(state.persisting, { $push: [cacheKey] })),
        ),
        lastActiveRequest: Date.now(),
      })),
    stopPersisting: (cacheKey) =>
      set((state) => {
        const index = state.persisting.indexOf(cacheKey);
        if (index === -1) {
          return state;
        }
        return {
          persisting: update(state.persisting, { $splice: [[index, 1]] }),
          lastActiveRequest: Date.now(),
        };
      }),
    resetPersisting: () => set(() => ({ persisting: [] })),

    requestDone: () => set(() => ({ lastActiveRequest: Date.now() })),

    error: undefined,
    setError: (error) => set(() => ({ error })),

    presentationAuth: {
      isInitialized: false,
      isAuthorized: false,
      userPresentationRole: 'Collaborator',
    },
    setPresentationAuth: (values) =>
      set((state) => ({
        presentationAuth: {
          ...state.presentationAuth,
          ...values,
          isInitialized: true,
        },
      })),
  }),
  shallow,
);

export const useSelectedSlideIds = (): [
  selectedSlideIds: Array<string>,
  setSelectedSlideIds: (ids: Array<string> | SetSelectedSlideIds) => void,
] => {
  return useGlobalState((state) => [
    state.selectedSlideIds,
    state.setSelectedSlideIds,
  ]);
};

export const useEditorFlag = (
  modeKey: EditorFlags,
): [currentMode: boolean, setMode: (value: boolean) => void] => {
  const setEditorFlag = useGlobalState((state) => state.setEditorFlag);
  const currentMode = useGlobalState((state) => state.editorFlags[modeKey]);
  const setMode = useCallback(
    (value: boolean) => setEditorFlag(modeKey, value),
    [modeKey, setEditorFlag],
  );

  return [currentMode, setMode];
};

export const useEditorError = <ErrorT = string>(): [
  error: ErrorT | undefined,
  setError: (error: ErrorT | undefined) => void,
] => {
  return useGlobalState((state) => [
    state.error as ErrorT | undefined,
    state.setError as (error: ErrorT | undefined) => void,
  ]);
};

export const useIsPersisting = (id?: string): boolean => {
  return useGlobalState((state) =>
    id ? state.persisting.includes(id) : state.persisting.length > 0,
  );
};

export const usePersisting = (): [
  startPersisting: (id: string) => void,
  stopPersisting: (id: string) => void,
  resetPersisting: () => void,
] => {
  const startPersisting = useGlobalState((state) => state.startPersisting);
  const stopPersisting = useGlobalState((state) => state.stopPersisting);
  const resetPersisting = useGlobalState((state) => state.resetPersisting);

  return useMemo(
    () => [startPersisting, stopPersisting, resetPersisting],
    [startPersisting, stopPersisting, resetPersisting],
  );
};

export const useRequestDone = () =>
  useGlobalState((state) => state.requestDone);

export const useLastRequestTimestamp = () =>
  useGlobalState((state) => state.lastActiveRequest);

/** Auth */
export const useIsAuthorized = () =>
  useGlobalState((state) => state.presentationAuth.isAuthorized);

export const useUserPresentationRole = () =>
  useGlobalState((state) => state.presentationAuth.userPresentationRole);

export const useSetPresentationAuth = () =>
  useGlobalState((state) => state.setPresentationAuth);

export const useIsPresentationAuthInitialized = () =>
  useGlobalState((state) => state.presentationAuth.isInitialized);
