import type { Series } from '@mentimeter/http-clients';

const DEFAULT_BACKGROUND = 'bgStrong';

export function getThemeBackgroundColor(
  series: Series,
  currentQuestionId?: string,
): string {
  const currentQuestion =
    series.questions.find(
      (q) => q.id === currentQuestionId || q.admin_key === currentQuestionId,
    ) ?? series.questions[0];

  return (
    currentQuestion?.theme_settings?.background_color ??
    series.theme.background_color ??
    DEFAULT_BACKGROUND
  );
}
