/** CX-REFACTOR: This is copied from applications/editor/src/features/core/editor/state/questions/constants.tsx and should be removed there.
 * Also these question-settings (QuestionConstantT) should be moved into the question modules themselves after it's been double checked which of it's props is actually used(!) */
import keyBy from 'lodash/keyBy';
import type { QuestionSubType, QuestionType } from '@mentimeter/http-clients';
import type { MusicCollection, QuestionConstant } from '../types';
import {
  DEFAULT_ALTERNATIVE_AMOUNT_SOFT_CAP,
  CHOICES_ALTERNATIVE_AMOUNT_HARD_CAP,
  SLIDE_BULLETS_ALTERNATIVE_AMOUNT_SOFT_CAP,
  SLIDE_BULLETS_ALTERNATIVE_AMOUNT_HARD_CAP,
  PIE_ALTERNATIVE_AMOUNT_MAX,
  PRIO_ALTERNATIVE_AMOUNT_HARD_CAP,
  RANKING_ALTERNATIVE_AMOUNT_SOFT_CAP,
  RANKING_ALTERNATIVE_AMOUNT_HARD_CAP,
  QUIZ_ALTERNATIVE_AMOUNT_HARD_CAP,
  QUIZ_ALTERNATIVE_AMOUNT_SOFT_CAP,
  RATING_ALTERNATIVE_AMOUNT_HARD_CAP,
  SCALES_ALTERNATIVE_AMOUNT_HARD_CAP,
  SCALES_ALTERNATIVE_AMOUNT_SOFT_CAP,
  WINNER_ALTERNATIVE_AMOUNT_HARD_CAP,
  QUIZ_DEFAULT_ALTERNATIVE_CHAR_HARD_CAP,
} from './limits';

const QUIZ_MUSIC_URL = 'https://static.mentimeter.com/static/music';
export const QUIZ_MUSIC: MusicCollection = [
  {
    artist: 'Joe E. Lee',
    title: 'A One Way Ticket Too Much',
    url: `${QUIZ_MUSIC_URL}/a_one_way_ticket_too_much.mp3`,
  },
  {
    artist: 'Gary Combs',
    title: 'Ladies on the Loose',
    url: `${QUIZ_MUSIC_URL}/ladies_on_the_loose.mp3`,
  },
  {
    artist: 'Birdies',
    title: 'Jumping Cricket',
    url: `${QUIZ_MUSIC_URL}/jumping_cricket.mp3`,
  },
];

const questionTypeChoices: QuestionConstant = {
  type: 'choices',
  name: 'Multiple Choice',
  moduleId: 'choices',
  icon: 'multiple-q',
  features: (subType: QuestionSubType | undefined) => ({
    title: { placeholder: 'Multiple Choice' },
    subheading: { type: 'description' },
    title_meta: true,
    leaderboard: false,
    profanityFilter: false,
    options: {
      imageUpload:
        subType !== 'pie' && subType !== 'donut' && subType !== 'dots',
      correctAnswers: true,
      description:
        'Enter the options you want your audience to be able to choose.',
      markdownMenu: false,
    },
    alerts: {
      softCap: DEFAULT_ALTERNATIVE_AMOUNT_SOFT_CAP,
      hardCap:
        subType === 'pie' || subType === 'donut' || subType === 'dots'
          ? PIE_ALTERNATIVE_AMOUNT_MAX
          : CHOICES_ALTERNATIVE_AMOUNT_HARD_CAP,
    },
    media: { image: true, video: false },
    music: false,
    extras: ['showCorrect', 'maxVotes'],
    segmentation: {
      display: true,
      enabled: subType !== 'pie' && subType !== 'donut',
    },
    subTypes: {
      position: 'bottom',
      types: ['choices', 'donut', 'pie', 'dots'],
    },
    reactions: false,
  }),
};

const questionTypeChoicesImages: QuestionConstant = {
  ...questionTypeChoices,
  type: 'choices_images',
};

const questionTypeWordCloud: QuestionConstant = {
  type: 'wordcloud',
  moduleId: 'wordcloud',
  name: 'Word Cloud',
  icon: 'word-cloud',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: 'Word Cloud' },
    subheading: { type: 'description' },
    title_meta: true,
    leaderboard: false,
    profanityFilter: true,
    options: false,
    alerts: {},
    media: { image: true, video: false },
    music: false,
    extras: ['multipleVotes'],
    segmentation: false,
    subTypes: false,
    reactions: false,
  }),
};

const questionTypeOpen: QuestionConstant = {
  type: 'open',
  moduleId: 'open',
  name: 'Open Ended',
  icon: 'comments',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: 'Open Ended' },
    subheading: { type: 'description' },
    title_meta: true,
    leaderboard: false,
    alerts: {},
    media: { image: true, video: false },
    music: false,
    options: false,
    profanityFilter: true,
    extras: ['multipleVotes'],
    segmentation: false,
    subTypes: {
      position: 'bottom',
      types: ['open', 'onebyone', 'flow'],
    },
    reactions: false,
  }),
};

const questionTypeScales: QuestionConstant = {
  type: 'scales',
  moduleId: 'scales',
  name: 'Scales',
  icon: 'scale-q',
  features: (subType: QuestionSubType | undefined) => ({
    title: { placeholder: 'Scales' },
    subheading: { type: 'description' },
    title_meta: true,
    leaderboard: false,
    profanityFilter: false,
    options: {
      imageUpload: false,
      correctAnswers: false,
      title: 'Statements',
      placeholder: 'Statement',
      description: 'Enter statements for your audience to rate.',
      markdownMenu: false,
    },
    alerts: {
      softCap: SCALES_ALTERNATIVE_AMOUNT_SOFT_CAP,
      hardCap: SCALES_ALTERNATIVE_AMOUNT_HARD_CAP,
    },
    media: { image: true, video: false },
    music: false,
    extras: subType === 'spider' ? ['allowSkip'] : ['showAverage', 'allowSkip'],
    segmentation: false,
    subTypes: {
      position: 'bottom',
      types: ['scales', 'spider'],
    },
    reactions: false,
  }),
};

const questionTypeRanking: QuestionConstant = {
  type: 'ranking',
  moduleId: 'ranking',
  name: 'Ranking',
  icon: 'ranking-q',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: 'Ranking' },
    subheading: { type: 'description' },
    title_meta: true,
    leaderboard: false,
    profanityFilter: false,
    options: {
      imageUpload: false,
      title: 'Items',
      placeholder: 'Item',
      description: 'Enter items for your audience to rate.',
      correctAnswers: false,
      markdownMenu: false,
    },
    alerts: {
      softCap: RANKING_ALTERNATIVE_AMOUNT_SOFT_CAP,
      hardCap: RANKING_ALTERNATIVE_AMOUNT_HARD_CAP,
    },
    media: { image: true, video: false },
    music: false,
    extras: [],
    segmentation: false,
    subTypes: false,
    reactions: false,
  }),
};

const questionTypeQfa: QuestionConstant = {
  type: 'qfa',
  moduleId: 'qfa',
  name: 'Q&A',
  icon: 'qfa',
  // unrestricted means available on free plan within question limit
  unrestricted: true,
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: 'Ask me anything' },
    subheading: false,
    title_meta: true,
    leaderboard: false,
    profanityFilter: false,
    options: false,
    alerts: {},
    media: { image: true, video: false },
    music: false,
    extras: [],
    segmentation: false,
    subTypes: false,
    reactions: false,
  }),
};

const questionTypePrioritisation: QuestionConstant = {
  type: 'prioritisation',
  moduleId: '100-points',
  name: '100 points',
  icon: 'prio-q',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: '100 points' },
    subheading: { type: 'description' },
    title_meta: true,
    leaderboard: false,
    profanityFilter: false,
    options: {
      imageUpload: false,
      description:
        'Enter the items you want your audience to allocate their points to (max 30).',
      title: 'Items',
      placeholder: 'Item',
      correctAnswers: false,
      markdownMenu: false,
    },
    alerts: {
      softCap: DEFAULT_ALTERNATIVE_AMOUNT_SOFT_CAP,
      hardCap: PRIO_ALTERNATIVE_AMOUNT_HARD_CAP,
    },
    music: false,
    media: { image: true, video: false },
    extras: [],
    segmentation: false,
    subTypes: false,
    reactions: false,
  }),
};

const questionTypeRating: QuestionConstant = {
  type: 'rating',
  moduleId: 'rating',
  name: '2 x 2 Grid',
  icon: 'rating-box',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: '2 x 2 Grid' },
    subheading: { type: 'description' },
    title_meta: true,
    leaderboard: false,
    profanityFilter: false,
    options: {
      imageUpload: false,
      correctAnswers: false,
      title: 'Items',
      placeholder: 'Item',
      description: 'Enter items for your audience to rate.',
      markdownMenu: false,
    },
    alerts: {
      softCap: DEFAULT_ALTERNATIVE_AMOUNT_SOFT_CAP,
      hardCap: RATING_ALTERNATIVE_AMOUNT_HARD_CAP,
    },
    music: false,
    media: { image: true, video: false },
    extras: ['allowSkip'],
    segmentation: false,
    subTypes: {
      position: 'bottom',
      types: ['two-values', 'four-values'],
    },
    reactions: false,
  }),
};

const questionTypePinOnImage: QuestionConstant = {
  type: 'pin_on_image',
  moduleId: 'pin-on-image',
  icon: 'pin-on-image',
  name: 'Pin on Image',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: 'Pin on Image' },
    title_meta: true,
    subheading: { type: 'description' },
    music: false,
    leaderboard: false,
    profanityFilter: false,
    options: false,
    alerts: {},
    media: { image: false, video: false },
    extras: ['showCorrect'],
    segmentation: false,
    subTypes: false,
    reactions: false,
  }),
};

const questionTypeMetadata: QuestionConstant = {
  type: 'metadata',
  moduleId: 'metadata',
  name: 'Quick Form',
  icon: 'metadata',
  pro: true,
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: 'Title of Quick Form' },
    subheading: { type: 'description' },
    title_meta: true,
    profanityFilter: false,
    options: false,
    leaderboard: false,
    music: false,
    alerts: {},
    media: { image: true, video: false },
    extras: [],
    segmentation: false,
    subTypes: false,
    reactions: false,
  }),
};

const questionTypeWinner: QuestionConstant = {
  type: 'winner',
  moduleId: 'winner',
  name: 'Who will win?',
  icon: 'trophy',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: 'Who will win?' },
    subheading: { type: 'description' },
    title_meta: true,
    leaderboard: false,
    profanityFilter: false,
    options: {
      imageUpload: false,
      correctAnswers: false,
      title: 'Contestants',
      placeholder: 'Contestant',
      description: 'Enter contestants for your audience to rate.',
      markdownMenu: false,
    },
    music: false,
    alerts: {
      softCap: DEFAULT_ALTERNATIVE_AMOUNT_SOFT_CAP,
      hardCap: WINNER_ALTERNATIVE_AMOUNT_HARD_CAP,
    },
    media: { image: true, video: false },
    extras: [],
    segmentation: false,
    subTypes: false,
    reactions: false,
  }),
};

const slideTypeBullets: QuestionConstant = {
  type: 'slide',
  moduleId: 'bullets',
  unrestricted: true,
  slideType: 'bullets',
  icon: 'heading-bullets',
  name: 'Bullets',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: {
      label: 'Heading',
      placeholder: 'Slide with bullets',
    },
    title_meta: true,
    alerts: {
      softCap: SLIDE_BULLETS_ALTERNATIVE_AMOUNT_SOFT_CAP,
      hardCap: SLIDE_BULLETS_ALTERNATIVE_AMOUNT_HARD_CAP,
    },
    subheading: false,
    leaderboard: false,
    music: false,
    options: {
      title: 'Bullets',
      description: 'Enter your bullets',
      placeholder: 'Bullet',
      correctAnswers: false,
      imageUpload: false,
    },
    media: { image: true, video: false },
    extras: ['showAllBullets', 'numberedList'],
    profanityFilter: false,
    segmentation: false,
    subTypes: false,
    reactions: true,
  }),
};

const slideTypeBulletsImage: QuestionConstant = {
  ...slideTypeBullets,
  slideType: 'bullets-image',
};

const slideTypeBig: QuestionConstant = {
  type: 'slide',
  moduleId: 'big',
  unrestricted: true,
  slideType: 'big',
  icon: 'big',
  name: 'Big',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: {
      label: 'Your BIG message',
      maxLength: 90,
      textfieldType: 'textarea',
      placeholder: 'BIG',
    },
    subheading: false,
    title_meta: true,
    leaderboard: false,
    options: false,
    alerts: {},
    media: { image: true, video: false },
    profanityFilter: false,
    extras: [],
    segmentation: false,
    subTypes: false,
    reactions: true,
    music: false,
  }),
};

const slideTypeMessage: QuestionConstant = {
  ...slideTypeBig,
  slideType: 'message',
};

export const INTERACTIVE_SLIDES_TYPES: Array<QuestionConstant> = [
  questionTypeChoices,
  questionTypeWordCloud,
  questionTypeOpen,
  questionTypeScales,
  questionTypeRanking,
  questionTypeQfa,
  {
    moduleId: 'numerical-question',
    name: 'Guess the Number',
    type: 'scales',
  },
  {
    moduleId: 'this-or-that',
    name: 'This or That',
    type: 'choices',
  },
  {
    moduleId: 'trafficlights',
    name: 'Traffic Lights',
    type: 'choices',
  },
  {
    moduleId: 'truth-or-lie',
    name: 'Truth or Lie',
    type: 'scales',
  },
  questionTypePrioritisation,
  questionTypeRating,
  questionTypeMetadata,
  questionTypeWinner,
  questionTypePinOnImage,
];

export const INTERACTIVE_QUESTION_TYPES: Array<QuestionConstant> = [
  ...INTERACTIVE_SLIDES_TYPES,
];

export const QUIZ_OPEN_QUESTION_TYPE: QuestionConstant = {
  type: 'quiz_open',
  moduleId: 'quiz-open',
  name: 'Type Answer',
  icon: 'quizOe',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: 'Type Answer' },
    subheading: false,
    title_meta: true,
    leaderboard: true,
    profanityFilter: false,
    options: {
      imageUpload: false,
      correctAnswers: false,
      markdownMenu: false,
    },
    music: QUIZ_MUSIC,
    alerts: {
      softCap: QUIZ_ALTERNATIVE_AMOUNT_SOFT_CAP,
      hardCap: QUIZ_ALTERNATIVE_AMOUNT_HARD_CAP,
    },
    media: { image: true, video: false },
    extras: ['timeBasedScoring'],
    segmentation: false,
    subTypes: false,
    reactions: false,
  }),
};

export const QUIZ_QUESTION_TYPE: QuestionConstant = {
  type: 'quiz',
  moduleId: 'quiz-choices',
  name: 'Select Answer',
  icon: 'quiz',
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: { placeholder: 'Select Answer' },
    subheading: false,
    title_meta: true,
    leaderboard: true,
    profanityFilter: false,
    options: {
      charHardCap: QUIZ_DEFAULT_ALTERNATIVE_CHAR_HARD_CAP,
      imageUpload: false,
      correctAnswers: true,
      markdownMenu: false,
    },
    music: QUIZ_MUSIC,
    alerts: {
      hardCap: QUIZ_ALTERNATIVE_AMOUNT_HARD_CAP,
      markCorrectAlert: true,
      canOnlyPickOneAlert: true,
    },
    media: { image: true, video: false },
    extras: ['timeBasedScoring'],
    segmentation: false,
    subTypes: false,
    reactions: false,
  }),
};

export const QUIZ_LEADERBOARD_TYPE: QuestionConstant = {
  type: 'quiz_leaderboard',
  moduleId: 'quiz-leaderboard',
  name: 'Leaderboard',
  icon: 'leaderboard',
  unrestricted: true,
  features: (_subTypes: QuestionSubType | undefined) => ({
    title: {
      label: 'Your title',
      placeholder: 'Leaderboard',
    },
    title_meta: true,
    music: false,
    subheading: false,
    leaderboard: false,
    profanityFilter: false,
    options: false,
    alerts: {},
    media: { image: false, video: false },
    extras: [],
    segmentation: false,
    subTypes: false,
    reactions: false,
  }),
};

export const RESTRICTED_INTEGRATION_SLIDES_TYPES: QuestionConstant[] = [
  {
    type: 'slide',
    moduleId: 'google-slides',
    name: 'Google slides',
    icon: 'powerpoint-slide',
  },
  {
    type: 'slide',
    moduleId: 'powerpoint',
    name: 'Powerpoint',
    icon: 'powerpoint-slide',
  },
];

export const INTEGRATION_SLIDES_TYPES: QuestionConstant[] = [
  ...RESTRICTED_INTEGRATION_SLIDES_TYPES,
  {
    type: 'slide',
    moduleId: 'miro',
    name: 'Miro',
    icon: 'powerpoint-slide',
    unrestricted: true,
  },
];

export const QUIZ_OPEN_QUESTION_TYPES = [
  QUIZ_QUESTION_TYPE,
  QUIZ_OPEN_QUESTION_TYPE,
  QUIZ_LEADERBOARD_TYPE,
];

export const QUIZ_QUESTION_TYPES = [QUIZ_QUESTION_TYPE, QUIZ_LEADERBOARD_TYPE];

export const ALL_QUIZ_QUESTION_TYPES = [
  QUIZ_QUESTION_TYPE,
  QUIZ_OPEN_QUESTION_TYPE,
];

export const ALL_QUIZ_SLIDE_TYPES = [
  ...ALL_QUIZ_QUESTION_TYPES,
  QUIZ_LEADERBOARD_TYPE,
];

export const CONTENT_SLIDES_TYPES: Array<QuestionConstant> = [
  {
    type: 'slide',
    moduleId: 'free-text',
    unrestricted: true,
    slideType: 'free-text',
    icon: 'heading-subheading',
    name: 'Text',
    features: (_subTypes: QuestionSubType | undefined) => ({
      title: {
        label: 'Text',
        placeholder: 'Slide with text',
      },
      title_meta: true,
      subheading: { type: 'heading', label: 'Subheading' },
      leaderboard: false,
      options: false,
      music: false,
      alerts: {},
      media: { image: true, video: false },
      profanityFilter: false,
      extras: [],
      segmentation: false,
      subTypes: false,
      reactions: true,
    }),
  },
  {
    type: 'slide',
    moduleId: 'heading',
    unrestricted: true,
    slideType: 'heading',
    icon: 'heading-subheading',
    name: 'Heading',
    features: (_subTypes: QuestionSubType | undefined) => ({
      title: {
        label: 'Heading',
        placeholder: 'Slide with heading',
      },
      title_meta: true,
      subheading: { type: 'heading', label: 'Subheading' },
      leaderboard: false,
      options: false,
      music: false,
      alerts: {},
      media: { image: true, video: false },
      profanityFilter: false,
      extras: [],
      segmentation: false,
      subTypes: false,
      reactions: true,
    }),
  },
  {
    type: 'slide',
    moduleId: 'paragraph',
    unrestricted: true,
    slideType: 'paragraph',
    icon: 'heading-paragraph',
    name: 'Paragraph',
    features: (_subTypes: QuestionSubType | undefined) => ({
      title: {
        label: 'Heading',
        placeholder: 'Slide with paragraph',
      },
      title_meta: true,
      subheading: { type: 'paragraph' },
      leaderboard: false,
      options: false,
      music: false,
      alerts: {},
      media: { image: true, video: false },
      profanityFilter: false,
      extras: [],
      segmentation: false,
      subTypes: false,
      reactions: true,
    }),
  },
  slideTypeBullets,
  {
    type: 'slide',
    moduleId: 'image',
    unrestricted: true,
    slideType: 'image',
    icon: 'picture-o',
    name: 'Image',
    features: (_subTypes: QuestionSubType | undefined) => ({
      title: {
        label: 'Image caption',
        placeholder: 'Image caption',
      },
      title_meta: true,
      subheading: false,
      music: false,
      leaderboard: false,
      profanityFilter: false,
      options: false,
      alerts: {},
      media: { image: true, video: false },
      extras: [],
      segmentation: false,
      subTypes: false,
      reactions: true,
      mediaLayout: true,
    }),
  },
  {
    type: 'slide',
    moduleId: 'video',
    icon: 'video',
    unrestricted: true,
    slideType: 'video',
    name: 'Video',
    features: (_subTypes: QuestionSubType | undefined) => ({
      title: {
        label: 'Video caption',
        placeholder: 'Video caption',
      },
      subheading: false,
      title_meta: true,
      music: false,
      leaderboard: false,
      profanityFilter: false,
      options: false,
      alerts: {},
      media: { image: true, video: true },
      extras: [],
      segmentation: false,
      subTypes: false,
      reactions: true,
    }),
  },
  slideTypeBig,
  {
    type: 'slide',
    moduleId: 'quote',
    unrestricted: true,
    slideType: 'quote',
    icon: 'quote',
    name: 'Quote',
    features: (_subTypes: QuestionSubType | undefined) => ({
      title: {
        label: 'Quote',
        maxLength: 250,
        textfieldType: 'textarea',
        placeholder: 'Quote',
      },
      subheading: {
        type: 'subheading',
        label: 'Who',
        placeholder: 'Who',
        textfieldType: 'input',
      },
      title_meta: true,
      leaderboard: false,
      options: false,
      alerts: {},
      media: { image: true, video: false },
      profanityFilter: false,
      extras: [],
      segmentation: false,
      subTypes: false,
      reactions: true,
      music: false,
    }),
  },
  {
    type: 'slide',
    moduleId: 'number',
    unrestricted: true,
    slideType: 'number',
    icon: 'number',
    name: 'Number',
    features: (_subTypes: QuestionSubType | undefined) => ({
      title: {
        label: 'Number',
        maxLength: 9,
        validation: {
          regex: /^[0-9]*$/,
          errorMessage: 'Only use numbers in this text field',
        },
        labelDescription: 'Add numbers to show a really big number',
        placeholder: '100',
        markdownMenu: false,
      },
      subheading: { type: 'subheading', label: 'What', placeholder: 'What' },
      title_meta: true,
      leaderboard: false,
      options: false,
      alerts: {},
      media: { image: true, video: false },
      profanityFilter: false,
      extras: [],
      segmentation: false,
      subTypes: false,
      reactions: true,
      music: false,
    }),
  },
  {
    type: 'slide',
    moduleId: 'instructions',
    unrestricted: true,
    slideType: 'instructions',
    icon: 'instructions',
    name: 'Instructions',
    features: (_subTypes: QuestionSubType | undefined) => ({
      title: {
        label: 'Your title',
        placeholder: 'Instructions',
      },
      subheading: false,
      title_meta: false,
      leaderboard: false,
      options: false,
      alerts: {},
      media: { image: false, video: false },
      profanityFilter: false,
      extras: [],
      segmentation: false,
      subTypes: false,
      reactions: true,
      music: false,
    }),
  },
  { moduleId: 'arrows', name: 'Arrows', type: 'slide', unrestricted: true },
  {
    moduleId: 'breathe',
    name: 'Breather',
    type: 'slide',
    unrestricted: true,
  },
  {
    moduleId: 'drum-roll',
    name: 'Drum Roll',
    type: 'slide',
    unrestricted: true,
  },

  {
    moduleId: 'loop',
    name: 'Loop',
    type: 'slide',
    unrestricted: true,
  },
  {
    moduleId: 'spin-the-wheel',
    name: 'Spin the Wheel',
    type: 'slide',
    unrestricted: true,
  },
  {
    moduleId: 'time-keep',
    name: 'Timer',
    type: 'slide',
    unrestricted: true,
  },
  {
    moduleId: 'timeline',
    name: 'Timeline',
    type: 'slide',
    unrestricted: true,
  },
  {
    type: 'slide',
    moduleId: 'document',
    unrestricted: true,
    slideType: 'document',
    icon: 'powerpoint-slide',
    name: 'Document',
    features: (_subTypes: QuestionSubType | undefined) => ({
      title: false,
      subheading: false,
      title_meta: false,
      music: false,
      leaderboard: false,
      profanityFilter: false,
      options: false,
      alerts: {},
      media: { image: true, video: false },
      extras: [],
      segmentation: false,
      subTypes: false,
      reactions: true,
    }),
  },
];

export const LEGACY_TYPES: Array<QuestionConstant> = [
  questionTypeChoicesImages,
  slideTypeBulletsImage,
  slideTypeMessage,
];

export const ALL_QUESTION_TYPES = [
  ...INTERACTIVE_SLIDES_TYPES,
  ...QUIZ_OPEN_QUESTION_TYPES,
  ...QUIZ_QUESTION_TYPES,
  ...CONTENT_SLIDES_TYPES,
  ...INTEGRATION_SLIDES_TYPES,
];

export const QUIZ_QUESTIONS_TYPES: QuestionType[] = ['quiz', 'quiz_open'];
export const QUIZ_SLIDES_TYPES: QuestionType[] = [
  ...QUIZ_QUESTIONS_TYPES,
  'quiz_leaderboard',
];

export const ALL_QUESTION_TYPES_BY_TYPE = keyBy<QuestionConstant>(
  ALL_QUESTION_TYPES,
  'type',
);

export const MAXIMUM_NUMBER_OF_SLIDES_MESSAGE =
  'You have reached the maximum number of slides.';
