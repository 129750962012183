/** CX-REFACTOR: Thise kind of checks and data should be moved into question-modules
 */
import { MentiError } from '@mentimeter/errors';
import type {
  ModuleId,
  Question,
  QuestionChoice,
  QuestionField,
  QuestionSubType,
} from '@mentimeter/http-clients';
import type { QuestionDefaults } from '@mentimeter/question-modules-types';
import type { DeepPartial } from '@mentimeter/ts-utils';
import { flatten, maxBy, uniq } from 'lodash';
import {
  ALL_QUESTION_TYPES,
  ALL_QUIZ_QUESTION_TYPES,
  ALL_QUIZ_SLIDE_TYPES,
  INTERACTIVE_QUESTION_TYPES,
  QUIZ_SLIDES_TYPES,
  RESTRICTED_INTEGRATION_SLIDES_TYPES,
} from '../constants/questionSettings';
import type { QuestionConstant } from '../types';
import { getFollowingLeaderboardId, getSlideById } from './slides';

export function isRestrictedInteractiveQuestion(
  question: Question | undefined,
) {
  return INTERACTIVE_QUESTION_TYPES.some(
    (qt: QuestionConstant) => question?.type === qt.type && !qt.unrestricted,
  );
}

export function isRestrictedQuizQuestion(question: Question | undefined) {
  return ALL_QUIZ_QUESTION_TYPES.some(
    (qt: QuestionConstant) => question?.type === qt.type && !qt.unrestricted,
  );
}

export function isRestrictedAllTypesQuestion(moduleId: ModuleId) {
  return ALL_QUESTION_TYPES.some(
    (qt) => qt.moduleId === moduleId && !qt.unrestricted,
  );
}

export function isProFeatureQuestion(moduleId: ModuleId | undefined) {
  return ALL_QUESTION_TYPES.some(
    (qt) => qt.moduleId === moduleId && Boolean(qt.pro),
  );
}

export function isRestrictedIntegrationQuestion(
  moduleId: ModuleId | undefined,
) {
  return RESTRICTED_INTEGRATION_SLIDES_TYPES.some(
    (qt) => qt.moduleId === moduleId,
  );
}

export const isLeaderboardSlide = (
  questionType: Question['type'] | undefined,
) => questionType === 'quiz_leaderboard';

export const isTypeQuizQuestion = (questionType: Question['type']) =>
  ALL_QUIZ_QUESTION_TYPES.map((q) => q.type).includes(questionType);

/**
 * @deprecated Use isQuizModule instead
 */
export const isTypeQuizSlide = (questionType: Question['type'] | undefined) =>
  Boolean(questionType && QUIZ_SLIDES_TYPES.includes(questionType));

export const isQuizModule = (moduleId: ModuleId | undefined) =>
  Boolean(
    moduleId &&
      ALL_QUIZ_QUESTION_TYPES.map((q) => q.moduleId).includes(moduleId),
  );
export const getIdToInsertAfter = (
  currentQuestion: Question | undefined,
  questions: Question[],
  isMobile?: boolean,
) => {
  if (!currentQuestion) return undefined;

  if (isMobile) return questions[questions.length - 1]!.id;

  const directlyFollowingLeaderboardId = getFollowingLeaderboardId(
    questions,
    currentQuestion.id,
  );
  if (
    currentQuestion?.type &&
    isTypeQuizQuestion(currentQuestion?.type) &&
    directlyFollowingLeaderboardId
  ) {
    return directlyFollowingLeaderboardId;
  }

  return currentQuestion.id;
};

export const calculateDeleteQuestionOperation = (
  questions: Array<Question>,
  activeQuestionId: string,
  questionIdsToDelete: Array<string>,
): { nextActiveQuestionId: string; questionIdsToDelete: Array<string> } => {
  const additionalIdsToDelete = flatten(
    questionIdsToDelete.map((id) => {
      const [question] = getSlideById(id, questions);
      if (!question) {
        throw new MentiError(
          'calculateDeleteQuestionOperation: Could not find active question',
          {
            feature: 'creation-experience',
          },
        );
      }
      return getDependantQuestions(questions, question);
    }),
  );

  const allQuestionIdsToDelete = uniq<string>([
    ...questionIdsToDelete,
    ...additionalIdsToDelete,
  ]);

  const nextActiveQuestionId = calculateNextActiveQuestionId(
    questions,
    activeQuestionId,
    allQuestionIdsToDelete,
  );

  return {
    nextActiveQuestionId,
    questionIdsToDelete: allQuestionIdsToDelete,
  };
};

export const getDependantQuestions = (
  questions: Array<Question>,
  question: Question,
) => {
  if (!question || !isTypeQuizQuestion(question.type)) {
    return [];
  }
  const numOfQuizQuestions = questions.filter((q) =>
    isTypeQuizQuestion(q.type),
  ).length;
  if (numOfQuizQuestions === 1) {
    return questions.filter((q) => isLeaderboardSlide(q.type)).map((q) => q.id);
  }
  const directlyFollowingLeaderboardId = getFollowingLeaderboardId(
    questions,
    question.id,
  );
  if (directlyFollowingLeaderboardId) {
    return [directlyFollowingLeaderboardId];
  }
  return [];
};

export const calculateNextActiveQuestionId = (
  questions: Array<Question>,
  activeQuestionId: string,
  questionIdsToDelete: string[],
) => {
  const isActiveQuestionBeingDeleted =
    questionIdsToDelete.includes(activeQuestionId);
  if (isActiveQuestionBeingDeleted) {
    const questionsLeft = questions.filter(
      (q) => !questionIdsToDelete.includes(q.id),
    );

    const currentIndex = questions.findIndex((q) => q.id === activeQuestionId);
    const newIndex = currentIndex - 1 > 0 ? currentIndex - 1 : 0;
    return (
      questionsLeft[newIndex]?.id ??
      questionsLeft[questionsLeft.length - 1]?.id ??
      activeQuestionId
    );
  }
  return activeQuestionId;
};

export const hasChangedFromDefaultValues = (
  question: Question,
  questionDefaults: QuestionDefaults,
) => {
  const hasNoQuestionAndChoices =
    !question.question && question.choices.length === 0;
  if (hasNoQuestionAndChoices) return false;
  const hasChangedQuestion = question.question !== questionDefaults.question;
  const hasChangedQuestionDescription =
    question.question_description !== questionDefaults.question_description;
  const hasChangedQuestionChoices = getHasChangedQuestionChoices(
    question.choices,
    questionDefaults.choices,
  );
  const hasChangedQuestionMetadataFields = getHasChangedMetadataFields(
    question.fields,
    questionDefaults.fields,
  );
  return (
    hasChangedQuestion ||
    hasChangedQuestionDescription ||
    hasChangedQuestionChoices ||
    hasChangedQuestionMetadataFields
  );
};

const getHasChangedMetadataFields = (
  fields: Array<QuestionField>,
  defaultFields: Array<QuestionField>,
) => {
  if (defaultFields && fields) {
    return (
      (defaultFields && fields.length < defaultFields.length) ||
      fields.some((f, index) => {
        const label = defaultFields?.[index]?.label ?? '';
        return f.label !== label;
      })
    );
  }
  return false;
};

const getHasChangedQuestionChoices = (
  choices: Array<QuestionChoice>,
  defaultChoices: Array<Partial<QuestionChoice>>,
) => {
  if (choices && defaultChoices) {
    return (
      (defaultChoices && choices.length < defaultChoices.length) ||
      choices.some((c, index) => {
        const label = defaultChoices?.[index]?.label ?? '';
        return c.label !== label;
      })
    );
  }
  return false;
};

function getNewChoicesWithIds(
  nextIdChoices: number,
  choices: Array<DeepPartial<QuestionChoice> | undefined> | undefined | null,
) {
  return (choices || []).map((c, index) => {
    return { ...c, id: index + nextIdChoices };
  });
}

export const mergeQuestionWithStaticDefaultValues = (
  currentQuestion: Question,
  newType: Question['type'],
  newSlideType: Question['slide_type'] | undefined | null,
  newStaticDefaults: DeepPartial<Question> | undefined = {},
  newSubType: QuestionSubType,
  moduleId: string | null | undefined,
) => {
  const commonProperties = {
    type: newType,
    slide_type: newSlideType,
    sub_type: newSubType,
    module_id: moduleId,
  };

  const payload = {
    ...currentQuestion,
    ...commonProperties,
  };

  let newChoices = null;
  if (newStaticDefaults['choices']) {
    const nextIdChoices = nextId(currentQuestion.choices);
    const deletedOldChoices = currentQuestion.choices.map((c) => {
      return { ...c, delete: true };
    });
    const newDefaultChoices = getNewChoicesWithIds(
      nextIdChoices,
      newStaticDefaults?.['choices'],
    );
    newChoices = [...deletedOldChoices, ...newDefaultChoices];
  }

  return {
    corePayload: {
      ...commonProperties,
      ...newStaticDefaults,
      ...(newChoices && { choices: newChoices }),
    },
    payload: {
      ...payload,
      ...newStaticDefaults,
      ...(newChoices && { choices: newChoices }),
    },
  };
};

export const newQuestionWithDefaultValues = (
  currentQuestion: Question,
  newType: Question['type'],
  newSlideType: Question['slide_type'] | null | undefined,
  newQuestionDefaults: DeepPartial<Question> | undefined,
  newSubType: QuestionSubType | undefined,
  moduleId: string | null | undefined,
) => {
  const nextIdChoices = nextId(currentQuestion.choices);
  const nextIdFields = nextId(currentQuestion.fields);
  const deletedOldChoices = currentQuestion.choices.map((c) => {
    return { ...c, delete: true };
  });
  const deletedOldFields = currentQuestion.fields.map((f) => {
    return { ...f, delete: true };
  });
  const newDefaultChoices = getNewChoicesWithIds(
    nextIdChoices,
    // @ts-expect-error-auto TS(2345) FIXME: Argument of type 'QuestionChoice[] | undefined' is... Remove this comment to see the full error message
    newQuestionDefaults.choices,
  );
  const newDefaultFields = (newQuestionDefaults?.fields || []).map(
    (f, index) => {
      return { ...f, id: index + nextIdFields };
    },
  );
  if (newQuestionDefaults) {
    delete newQuestionDefaults.choices;
    delete newQuestionDefaults.fields;
  }
  const corePayload = {
    type: newType,
    slide_type: newSlideType,
    // I wish we didn't add arbitrary non-data props like "delete" to the data objects. It makes it hard to type.
    choices: deletedOldChoices.concat(
      newDefaultChoices as typeof deletedOldChoices,
    ),
    fields: deletedOldFields.concat(
      newDefaultFields as typeof deletedOldFields,
    ),
    sub_type: newSubType,
    module_id: moduleId,
    ...newQuestionDefaults,
  };
  const payload = {
    ...corePayload,
    ...{ choices: newDefaultChoices },
    ...{ fields: newDefaultFields },
  };
  return { corePayload, payload };
};

const nextId = <T extends { id: number }>(choices: Array<T>) => {
  const max = maxBy<any>(choices, 'id');
  return (max ? max.id : 0) + 1;
};

export const filterQuizQuestions = (questions: Array<Question>) =>
  questions.filter((q) =>
    ALL_QUIZ_QUESTION_TYPES.some((qt) => q.type === qt.type),
  );

export const getQuizQuestions = (questions: Array<Question>) =>
  questions.filter((q) =>
    ALL_QUIZ_QUESTION_TYPES.some((qt) => q.type === qt.type),
  );

export const getQuizQuestionsWithLeaderboard = (questions: Array<Question>) =>
  questions.filter((q) =>
    ALL_QUIZ_SLIDE_TYPES.some((qt) => q.type === qt.type),
  );

export const findLastQuizSlideIndex = (questions: Array<Question>) => {
  const quizSlides = getQuizQuestionsWithLeaderboard(questions);
  const lastQuizSlideId = quizSlides[quizSlides.length - 1]?.id ?? '';
  return questions.findIndex((question) => question.id === lastQuizSlideId);
};

export const findDirectlyFollowingLeaderboardById = (
  questions: Array<Question>,
  relativeQuestionId: string | undefined,
): Question | null => {
  if (!relativeQuestionId) {
    return null;
  }

  const index = questions.findIndex((q) => q.id === relativeQuestionId);
  try {
    const nextQuestion = questions[index + 1];
    if (nextQuestion) {
      if (isLeaderboardSlide(nextQuestion.type)) {
        return nextQuestion;
      }
    }

    return null;
  } catch {
    return null;
  }
};
