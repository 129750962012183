import type { Series } from '@mentimeter/http-clients';
import useSWR from 'swr';
import { useRegionalDataFetchers } from '@mentimeter/core-hooks';
import { MentiError } from '@mentimeter/errors';
import { isQuizModule } from '@mentimeter/editor-essentials/calculations/questionFilters';

const CLEAR_QUIZ_CACHE = false;

export const swrSettings = {
  revalidateOnMount: false,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

interface UnloadedPropsT {
  displayQuestionId?: string | undefined;
  seriesId: string;
  preloadedSeries?: never;
}

interface LoadedPropsT {
  displayQuestionId?: string | undefined;
  preloadedSeries: Series;
  seriesId?: never;
}

type PropsT = UnloadedPropsT | LoadedPropsT;

export function useSlidePreviewData({
  seriesId: unloadedSeriesId,
  displayQuestionId,
  preloadedSeries,
}: PropsT) {
  const { getSeries, getResults, getQuizPlayersScore } =
    useRegionalDataFetchers();

  const seriesId = unloadedSeriesId ?? preloadedSeries.id;

  const { data: series } = useSWR(
    slidePreviewSeriesKey(seriesId),
    async () => {
      return getSeries(seriesId);
    },
    { suspense: true, ...swrSettings },
  );

  const resultsQuestionId = getResultsQuestionId({
    displayQuestionId,
    preloadedSeries: series,
  });
  const resultsQuestion = series?.questions.find(
    (question) => question.id === resultsQuestionId,
  );
  if (!resultsQuestion)
    throw new MentiError('Could not find selected question for slide preview', {
      feature: 'slide-preview',
    });

  const resultType =
    isQuizModule(resultsQuestion.module_id) ||
    resultsQuestion.type === 'quiz_leaderboard'
      ? 'quiz'
      : 'question';

  const {
    data: { questionResults, quizPlayersScore },
  } = useSWR(
    `slide-preview-results:${resultsQuestionId}:${resultType}`,
    async () => {
      if (resultType === 'quiz') {
        const quizPlayersScore = await getQuizPlayersScore(
          seriesId,
          resultsQuestionId,
          CLEAR_QUIZ_CACHE,
        );
        return {
          quizPlayersScore,
          questionResults: null,
        };
      } else {
        const results = await getResults(seriesId, resultsQuestionId);
        return {
          questionResults: results,
          quizPlayersScore: null,
        };
      }
    },
    { suspense: true, ...swrSettings },
  );

  return {
    series,
    resultsQuestionId,
    questionResults,
    quizPlayersScore,
  };
}

function slidePreviewSeriesKey(seriesId: string): string {
  return ['slide-preview-series-', seriesId].join('');
}

export function getResultsQuestionId({
  displayQuestionId,
  preloadedSeries,
}: {
  preloadedSeries: Series;
  displayQuestionId: string | undefined;
}) {
  if (displayQuestionId) return displayQuestionId; // Always use specified question ID if provided;

  const firstQuestion = preloadedSeries.questions[0];
  if (!firstQuestion)
    throw new MentiError(
      'Cannot load Slide Preview with a Series that has no questions',
      {
        feature: 'paid-user-growth',
      },
    );

  return firstQuestion.id; // Default to fetch the results for the first question, if no specific question ID is provided.
}
