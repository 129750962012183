import type { Rule } from '@mentimeter/ragnar-styled';
import {
  FLEX_CONTAINER,
  LAYOUT,
  POSITION,
  styled,
} from '@mentimeter/ragnar-styled';
import { addUnit } from '@mentimeter/ragnar-utils';
import React, { forwardRef } from 'react';

type DirectionT = 'row' | 'column';

export interface TabGenericProps {
  active?: boolean | undefined;
  disabled?: boolean | undefined;
  direction?: DirectionT;
  compact?: boolean;
}

export const rule: Rule<TabGenericProps> = ({
  active,
  disabled,
  theme,
  compact = false,
  direction = 'row',
}) => {
  const generalStyling = {
    margin: '-1px',
    appearance: 'none',
    WebkitAppearance: 'none',
    backgroundColor: 'transparent',
    transition: `all ${theme.durations[0]}s ease-in-out`,
    outline: 'none',
    cursor: 'pointer',
    color: theme.colors.textPrimary,
    textAlign: direction === 'row' ? 'center' : 'left',
    lineHeight: compact
      ? addUnit(theme.kosmosLineHeight.fixed[100])
      : addUnit(theme.kosmosLineHeight.fixed[125]),
    paddingTop: compact
      ? addUnit(theme.kosmosSpacing.space2)
      : addUnit(theme.kosmosSpacing.space4),
    paddingBottom: compact
      ? addUnit(theme.kosmosSpacing.space2)
      : addUnit(theme.kosmosSpacing.space4),
    textDecoration: 'none',
  };

  if (disabled) {
    return {
      ...generalStyling,
      pointerEvents: 'none',
      boxShadow: `inset 0px -2px 0px  ${theme.colors.borderDisabled}`,
    };
  }
  return {
    ...generalStyling,
    boxShadow: active
      ? `inset 0px -2px 0px  ${theme.colors.secondary}`
      : 'none',
    '&:active': {
      boxShadow: `inset 0px -2px 0px  ${theme.colors.secondary}`,
    },
    '&:focus:not(:focus-visible)': {
      boxShadow: `inset 0px -2px 0px  ${theme.colors.secondary}`,
    },
    '&:focus-visible': {
      outline: `4px solid ${theme.colors.interactiveFocused}`,
      outlineOffset: '2px',
      boxShadow: `inset 0px -2px 0px  ${theme.colors.secondary}`,
    },
    '@media (hover: hover)': {
      '&:hover': {
        boxShadow: `inset 0px -3px 0px  ${active ? theme.colors.secondary : theme.colors.borderNeutralWeakest}`,
      },
      '&:focus:not(:focus-visible)': {
        boxShadow: `inset 0px -3px 0px  ${theme.colors.secondary}`,
      },
    },
  };
};

const TabGenericStyled = styled('button', {
  displayName: 'TabGeneric',
})(rule, 'kosmosSpacing', 'color', ...LAYOUT, ...FLEX_CONTAINER, ...POSITION);

export type TabGenericStyledProps = React.ComponentProps<
  typeof TabGenericStyled
>;

export const TabGeneric = forwardRef<
  HTMLAnchorElement | HTMLButtonElement | null,
  TabGenericStyledProps
>((props, ref) => (
  <TabGenericStyled
    display="flex"
    flexDirection="column"
    flex="none"
    width="auto"
    alignItems="flex-start"
    position="relative"
    role="tab"
    // Reset selector
    className="r-box"
    {...props}
    ref={ref}
  />
));
