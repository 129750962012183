import React, { Suspense } from 'react';
import type {
  QuizPlayerScore,
  Series,
  SessionResult,
} from '@mentimeter/http-clients';
import { Skeleton } from '@mentimeter/ragnar-ui';
import dynamic from 'next/dynamic';
import {
  getThemeBackgroundColor,
  PreviewContainer,
} from '@mentimeter/slide-preview-shared';
import {
  seriesCacheKey,
  questionsCacheKey,
  resultCacheKey,
  quizPlayersScoreCacheKey,
} from '@mentimeter/core-hooks';
import { MentiError, ErrorBoundary } from '@mentimeter/errors';
import { SWRConfig } from 'swr';
import type { ExampleResultsMode } from '@mentimeter/slide-preview';
import { useSlidePreviewData, swrSettings } from './fetchers';
import { RevealWhenFirstVisible } from './utils';

const ERROR_FALLBACK_BACKGROUND = 'neutral';

const RUNNING_IN_UNIT_TEST = process?.env?.['JEST_WORKER_ID'] !== undefined;

interface CommonLazySlidePreviewProps {
  displayQuestionId?: string;
  exampleResultsMode?: ExampleResultsMode;
  enableNavigation?: boolean;
  enableTestVotesIndicator?: boolean;
  showPreview?: boolean;
  insetBorderRadius?: number;
}

interface UnloadedLazySlidePreviewProps extends CommonLazySlidePreviewProps {
  displayQuestionId?: string;
  seriesId: string;
  series?: never;
}

interface LoadedLazySlidePreviewProps extends CommonLazySlidePreviewProps {
  seriesId?: never;
  series: Series;
}

type LazySlidePreviewProps =
  | UnloadedLazySlidePreviewProps
  | LoadedLazySlidePreviewProps;

export const LazySlidePreview = React.memo(LazySlidePreviewComponent);

export function LazySlidePreviewComponent({ ...props }: LazySlidePreviewProps) {
  if (RUNNING_IN_UNIT_TEST) return null;

  return (
    <ErrorBoundary
      feature="paid-user-growth"
      errorMessage="Could not load presentation preview on home view"
      fallback={<PreviewContainer background={ERROR_FALLBACK_BACKGROUND} />}
    >
      <RevealWhenFirstVisible>
        <LoadSlidePreview {...props} />
      </RevealWhenFirstVisible>
    </ErrorBoundary>
  );
}

function LoadSlidePreview({
  seriesId,
  series,
  displayQuestionId,
  exampleResultsMode,
  enableNavigation,
  enableTestVotesIndicator,
  showPreview = true,
  insetBorderRadius,
}: LazySlidePreviewProps) {
  if (!seriesId && !series)
    throw new MentiError(
      'Cannot load slide preview without seriesId or series set',
      { feature: 'paid-user-growth' },
    );

  const optionalProperties = {
    ...(displayQuestionId && { displayQuestionId }),
    ...(exampleResultsMode && { exampleResultsMode }),
    ...(enableNavigation && { enableNavigation }),
    ...(enableTestVotesIndicator && { enableTestVotesIndicator }),
    ...(showPreview && { showPreview }),
    ...(insetBorderRadius && { insetBorderRadius }),
  };

  return (
    <Suspense
      fallback={
        <PreviewContainer>
          <Skeleton height="100%" variant="image" />
        </PreviewContainer>
      }
    >
      {seriesId && (
        <UnloadedSlidePreviewFetcher
          seriesId={seriesId}
          {...optionalProperties}
        />
      )}
      {series && <LoadedSlidePreview series={series} {...optionalProperties} />}
    </Suspense>
  );
}

function UnloadedSlidePreviewFetcher({
  seriesId,
  displayQuestionId,
  showPreview,
  ...rest
}: UnloadedLazySlidePreviewProps) {
  const { series, questionResults, resultsQuestionId, quizPlayersScore } =
    useSlidePreviewData({
      seriesId,
      displayQuestionId,
    });

  if (!showPreview) return <PlaceholderSlidePreview series={series} />;

  const Preview = getSlidePreview(series);

  return (
    <CacheSeries
      series={series}
      questionResults={questionResults}
      resultsQuestionId={resultsQuestionId}
      quizPlayersScore={quizPlayersScore}
    >
      <Preview
        series={series}
        displayQuestionId={displayQuestionId}
        {...rest}
      />
    </CacheSeries>
  );
}

function LoadedSlidePreview({
  series: preloadedSeries,
  displayQuestionId,
  showPreview,
  ...rest
}: LoadedLazySlidePreviewProps) {
  const { series, questionResults, resultsQuestionId, quizPlayersScore } =
    useSlidePreviewData({
      preloadedSeries,
      displayQuestionId,
    });

  if (!showPreview) return <PlaceholderSlidePreview series={series} />;

  const Preview = getSlidePreview(series);

  return (
    <CacheSeries
      series={series}
      questionResults={questionResults}
      resultsQuestionId={resultsQuestionId}
      quizPlayersScore={quizPlayersScore}
    >
      <Preview
        series={series}
        {...(displayQuestionId && { displayQuestionId })}
        {...rest}
      />
    </CacheSeries>
  );
}

function getSlidePreview(series: Series) {
  return dynamic(
    () => import('@mentimeter/slide-preview').then((m) => m.SlidePreview),
    {
      ssr: false,
      loading: () => <PlaceholderSlidePreview series={series} />,
    },
  );
}

function CacheSeries({
  series,
  questionResults,
  quizPlayersScore,
  resultsQuestionId,
  children,
}: {
  series: Series;
  questionResults: SessionResult | null;
  quizPlayersScore: QuizPlayerScore[] | null;
  resultsQuestionId: string;
  children: React.ReactNode;
}) {
  return (
    <SWRConfig
      value={{
        ...swrSettings,
        fallback: {
          [seriesCacheKey(series.id)]: series,
          [questionsCacheKey(series.id)]: series.questions,
          [resultCacheKey(resultsQuestionId)]: questionResults,
          [quizPlayersScoreCacheKey(resultsQuestionId)]: quizPlayersScore,
        },
      }}
    >
      {children}
    </SWRConfig>
  );
}

function PlaceholderSlidePreview({
  series,
  currentQuestionId,
}: {
  series: Series;
  currentQuestionId?: string;
}) {
  const background = getThemeBackgroundColor(series, currentQuestionId);
  return <PreviewContainer background={background} />;
}
