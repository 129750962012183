import { Box, type BoxT } from '@mentimeter/ragnar-ui';
import React, { forwardRef } from 'react';

interface PropsT {
  background?: BoxT['bg'];
  children?: React.ReactNode;
}

export const PreviewContainer = forwardRef<HTMLDivElement | undefined, PropsT>(
  // eslint-disable-next-line react/jsx-no-useless-fragment
  ({ background = 'transparent', children = <></> }: PropsT, ref) => {
    return (
      <Box
        ref={ref}
        bg={background}
        width="100%"
        overflow="hidden"
        alignItems="center"
        position="relative"
        extend={() => ({
          aspectRatio: '16/9',
        })}
      >
        {children}
      </Box>
    );
  },
);

export function ColorOverlay({
  transitionTimeMs = 700,
  background,
  opacity,
}: {
  transitionTimeMs?: number;
  background: React.CSSProperties['background'];
  opacity: React.CSSProperties['opacity'];
}) {
  return (
    <Box
      extend={() => ({
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        width: '100%',
        height: '100%',
        transition: `opacity ${transitionTimeMs}ms ease-in`,
        background,
        opacity,
      })}
    />
  );
}
