import * as React from 'react';
import type { BadgeRibbonT } from '../Ribbon/badgeRibbonTypes';
import { Box, type BoxT } from '../Box';
import { Tab } from '../Tab/Tab';
import type { BadgeT } from '../Badge';

export interface TabActionT {
  key: string;
  id?: string;
  title: string;
  active?: boolean;
  disabled?: boolean;
  badge?: BadgeRibbonT | BadgeT | undefined;
  onClick: React.MouseEventHandler;
}

export interface TabsT extends BoxT {
  fullWidth?: boolean;
  actions: Array<TabActionT>;
  compact?: boolean;
}

export const Tabs = ({
  actions,
  fullWidth,
  compact = false,
  ...rest
}: TabsT) => {
  return (
    <Box
      role="tablist"
      flexDirection="row"
      width={fullWidth ? '100%' : undefined}
      gap={fullWidth ? 'space0' : 'space6'}
      {...rest}
      borderBottomWidth="1px"
      borderColor="borderNeutralWeakest"
      borderStyle="solid"
    >
      {actions.map((a) => (
        <Tab
          key={a.key}
          id={a.id}
          aria-label={a.title}
          aria-selected={a.active}
          active={a.active}
          disabled={a.disabled}
          onClick={a.onClick}
          fullWidth={fullWidth}
          width={fullWidth ? '100%' : 'auto'}
          badge={a.badge}
          compact={compact}
        >
          {a.title}
        </Tab>
      ))}
    </Box>
  );
};
