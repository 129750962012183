import * as React from 'react';
import type { BadgeRibbonT } from '../Ribbon/badgeRibbonTypes';
import { Text } from '../Text';
import { Badge, BadgeWrap, type BadgeT } from '../Badge';
import { TabGeneric } from './TabGeneric';

export interface TabT extends React.ComponentProps<typeof TabGeneric> {
  children: React.ReactNode;
  fullWidth?: boolean | undefined;
  badge?: BadgeRibbonT | BadgeT | undefined;
  compact?: boolean;
}

const isBadge = (badge: BadgeT | BadgeRibbonT | undefined): badge is BadgeT => {
  return (badge as BadgeT)?.type !== undefined;
};

export const Tab = ({
  disabled,
  active = false,
  children,
  badge,
  fullWidth = true,
  compact = false,
  ...props
}: TabT) => {
  let badgeToRender;

  if (isBadge(badge)) {
    badgeToRender = badge;
  } else if (badge) {
    badgeToRender = <Badge compact type={badge} />;
  }

  return (
    <TabGeneric
      disabled={disabled}
      active={active}
      alignItems="center"
      flex={fullWidth ? 1 : 'none'}
      {...props}
      compact={compact}
    >
      <BadgeWrap
        flex={1}
        badge={badgeToRender && (badgeToRender as React.ReactNode)}
        compact
      >
        <Text
          color={disabled ? 'textDisabled' : active ? 'text' : 'textWeaker'}
          fontWeight="semiBold"
          lineHeight="125"
          fontSize={compact ? '87.5' : '100'}
          truncate
        >
          {children}
        </Text>
      </BadgeWrap>
    </TabGeneric>
  );
};
