import type { CoreClient, Series } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import { getRegionBySeriesId } from '@mentimeter/region';
import React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useSWRConfig } from 'swr';
import { seriesCacheKey } from './cache-keys';
import { useCoreHookContext } from './core-context';
import type { UseSeries, WrappedMutator } from './types';
import { useData } from './use-data';

type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
  ? (...args: P) => R
  : never;

export const useSeries: UseSeries = (seriesId, config) => {
  const cacheKey = seriesId ? seriesCacheKey(seriesId) : undefined;
  const { device_id } = useCoreHookContext();
  const { data, mutate, error, revalidate, lazyData } = useData<Series>(
    {
      cacheKey,
      seriesId,
      fetcher: async () => {
        const { data } = await core({
          region: getRegionBySeriesId(seriesId as string),
        }).series.get(seriesId as string);
        return data;
      },
    },
    config,
  );

  const apiCall: OmitFirstArg<CoreClient['series']['put']> = React.useCallback(
    (newData, config) =>
      core().series.put(
        // @ts-expect-error TS(2345)
        seriesId,
        {
          ...newData,
          device_id,
        },
        config,
      ),
    [device_id, seriesId],
  );

  const update: ReturnType<UseSeries>['update'] = React.useCallback(
    async (newData, batchedStateUpdate, config) => {
      const { data: response } = await apiCall(newData, config);

      unstable_batchedUpdates(() => {
        mutate<Series>(response, { revalidate: false });
        if (batchedStateUpdate) {
          batchedStateUpdate();
        }
      });

      return response;
    },
    [apiCall, mutate],
  );

  return {
    data,
    lazyData,
    revalidate,
    error,
    update,
  };
};

export const useSeriesMutate = (seriesId: string) => {
  const { mutate } = useSWRConfig();
  return React.useCallback<WrappedMutator<Series>>(
    (data, options) => {
      mutate(seriesCacheKey(seriesId), data, {
        revalidate: false,
        ...options,
      });
    },
    [seriesId, mutate],
  );
};
